import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-case-studies-details-page',
    templateUrl: './case-studies-details-page.component.html',
    styleUrls: ['./case-studies-details-page.component.scss']
})
export class CaseStudiesDetailsPageComponent implements OnInit {
    currentItem = 0;
    pages = ['nio', 'aeo', 'bpo', 'inv', 'med', 'ts']
    
    constructor(public route: ActivatedRoute,) { 
        this.route.params.subscribe(params => {
            this.currentItem = this.pages.indexOf( params.type)
        })
    }

    subTitles = ['Autonomous Vehicles - Digital Cockpit', 'Customer 360 Platform', 'Billing Operations', 'Investment  Management', 'IT Services Management', 'Technology Support']
    detailsImages = [
        {
            img: 'assets/img/projects/projects-details1.jpg'
        },
        {
            img: 'assets/img/projects/projects-details1.jpg'
        },
        {
            img: 'assets/img/projects/projects-details1.jpg'
        },
        {
            img: 'assets/img/projects/projects-details1.jpg'
        },
        {
            img: 'assets/img/projects/projects-details1.jpg'
        }, {
            img: 'assets/img/projects/projects-details1.jpg'
        }
    ]
    ngOnInit(): void {
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Case Studies',
            subTitle: this.subTitles[0],
        },
        {
            title: 'Case Studies',
            subTitle: this.subTitles[1],
        },
        {
            title: 'Case Studies',
            subTitle: this.subTitles[2],
        },
        {
            title: 'Case Studies',
            subTitle: this.subTitles[3],
        },
        {
            title: 'Case Studies',
            subTitle: this.subTitles[4],
        },
        {
            title: 'Case Studies',
            subTitle: this.subTitles[5],
        }
    ]

    caseStudiesDetailsImage: Image[] = [
        {
            img: 'assets/img/projects/projects-details1.jpg'
        }
    ]
    caseStudiesDetailsDesc: DetailsDesc[] = [
        {
            subTitle: 'NIO - Digital Cockpit Validation',
            title1: 'Super Computer: Display Vehicle Information',
            paragraph1: 'Validation of Cockpit Module is challening as industry is moving ahead with lot of features towards autonomous driving displaying and alerting user correctly becomes crucial. Venturesoft Global team help NIO to perform ECE testng, ADAS and autonomous feature validations. Out team worked to come with development of test oplans and test cases, along with traceability of requirements.',
            img1: 'assets/img/projects/img2.jpg',
            title2: 'Highlights',
            paragraph2: 'Our team got on board to put in place global Integration solution architecture with new-generation IT infrastructure flexible to changing business requirements. The target was to create an enterprise-level service platform instead of point-to-point integration. We replaced the legacy Exchange Tandem architecture and infrastructure with next-generation Service Oriented Architecture (SOA)',
            paragraph3: 'We defined the SOA roadmap and governance, and designed, built, and deployed an SOA-based solution. This solution provided common business processes, routing, and aggregation services for the enterprise. A layered design approach using Oracle SOA suite ensured that the interface was flexible and could accommodate changes quickly with minimal impact.',
            title3: 'Results',
            paragraph4: 'As a result of this project, the client was able to get IT infrastructure that aligned with its business vision and helped it design its vehicle digital cockpit better. The global Integration solution helped reduce engagement cycle time and development costs by 10-15%; in addition, infrastructure standardization and optimization also reduced costs by 15-20%. Higher reusability of resources, design patterns, components, data, documents, artifacts, and infrastructure assets improved productivity by 10%.',
        },
        {
            subTitle: 'AEO: Master Data Consolidation',
            title1: 'American Eagle Outfitters - Build customer 360 Platform',
            paragraph1: 'AEO wanted Venturesoft Global team help build a platform that will connnect AEO\'s data sources to a central hub for secure data exchange.  All data flows across from across various enterprise data sources should be merged, processed to identify a customer, to study their profile and improve the customer experience across the AEO and its subsidiaries ecommerce portals.',
            img1: 'assets/img/projects/img2.jpg',
            title2: 'Achievements',
            paragraph2: 'Our team enabled AEO\'s systems to recognize every customer with a single, reconciled customer profile. Empowered data stewards to comply with data provacy policies.',
            paragraph3: 'Venturesoft Global Team studied the features of our customer’s BPO platform and reviewed the architecture, design, code/coding framework, etc. to address each of the challenges raised by the customer. ',
            title3: 'Results',
            paragraph4: 'As a result of this project, the client was able to see  improved customer traction and loyalty. By anticipating  customer needs and developing a strategic campaign plan, AEO keeps business on top-of-mind. The customer traction turned into more sales and resulted in keeping customers coming back again and again therefore improving the online sales by 58%',
        },
        {
            subTitle: 'BPO: Billing Operations',
            title1: 'Largest billing and management services in North America',
            paragraph1: 'Our customer had realized that technology was the key to effective management of billing and collections process and that relying on good people, was not enough to be successful, way back in mid 2000’s. The need to monitor every phase of the process closely was critical. Hence, our customer built a billing and reimbursement platform that enabled them to provide higher level of service to their customers. As early adopters of the \“Platform BPO\” concept, our customer built a platform that evolved into a multi-tenant platform over years. This platform strengthened our customer\’s core service offerings for the niche anaesthesiologists and CRNAs market',
            img1: 'assets/img/projects/img2.jpg',
            title2: 'Achievements',
            paragraph2: 'Implemented BPEL based automated workflow into the system that helps the BPE users/managers from manually tracking the status of each. This also takes care of activities like overdue notification, escalation, etc',
            paragraph3: 'We defined the architecture to be robust and resilient to  combine data coming from  customer channels, call center, CRM,POS, etc. With our architecture, AEO was able to use all the data how they wanted, when they wanted and keep it where they wanted to. We basically set up the Preparation Rules, Match Rules and Reconciliation Rules to process the data',
            title3: 'Results',
            paragraph4: 'With our help, our customer could reduce the web-based application’s response time by more than 65%. The page response time is maintained at a max allowed duration of 8 seconds so that it does not disturb the users from continuing with the next level of processing',
        },{
            subTitle: 'Investment Management Leader',
            title1: 'Investment Management - Operations Fine-tuned',
            paragraph1: 'Our client offers state-of-the-art deal management, investor relations and portfolio performance solutions to global investment managers that manage in excess of a trillion dollars. Our client is well known for offering leading financial accounting and performance analysis products in addition to leading intelligent research solutions for superior investments research and analysis.',
            img1: 'assets/img/projects/img2.jpg',
            title2: 'Achievements',
            paragraph2: 'Over a period of time the data collected from the various sources grew exponentially with the expansion of customer base and as a result, on-the-fly alert generation latency increased significantly.This was a major pain point for our client who wanted a lowlatency and scalable solution',
            paragraph3: 'We ingested the company information, related news and press releases from a variety of configurable data sources, processed the ingested data and indexed them in Apache Solr- a popular, super-fast open source enterprise search platform. Developed a module to allow users to create and publish models on demand from specified keywords using OpenNLP’s classification algorithm. Reduced the latency significantly by computing the scores of millions of press releases on the fly using the Apache Spark computing engine and generated alerts for the companies matching the keywords.',
            title3: 'Results',
            paragraph4: 'Apache Spark was chosen after carefully analyzing in detail the client’s use case requirements. Spark delivered high throughput processing with extremely low latency. In our benchmarking, the total time taken for the alert generation, right from scanning and processing approximately 50,000 press releases to revealing insights, significantly reduced from around 45 minutes (even with Java native multi-threading) to less than 2 minutes using Spark running on a commodity server in the production environment.',
        },{
            subTitle: 'IT services Management',
            title1: 'ITSM For a leading Medical Devices Manufactureer',
            paragraph1: 'The client reached out to Venturesoft Global team  because they did not have any IT professionals in-house and needed help handling growing support needs as their business expanded. They had one CPA/CGMA on staff who was tech-savvy and took care of internal end users\' support needs. However, as the firm took on more clients, it was no longer feasible for that staff member to manage IT on top of his other professional obligations. Additionally, the client serves Fortune 100 and 500 companies and assists them in dealings with insurers, so they needed to maintain compliance and the best possible cybersecurity posture',
            img1: 'assets/img/projects/img2.jpg',
            title2: 'Achievements',
            paragraph2: 'Additionally, the client had built an internal database for tracking claims and opening tickets that they needed to make sure met their customers\' compliance and security standards. Our senior IT consultants worked with the client and one of our trusted partners to understand the client\'s requirements and ultimately recommended migrating their application to Microsoft Dynamics, which not only provides the necessary security and compliance tools but also provides the client with a platform on which to grow and manage their business.',
            paragraph3: 'We defined the architecture to be robust and resilient to  combine data coming from  customer channels, call center, CRM,POS, etc. With our architecture, AEO was able to use all the data how they wanted, when they wanted and keep it where they wanted to. We basically set up the Preparation Rules, Match Rules and Reconciliation Rules to process the data',
            title3: 'Results',
            paragraph4: 'Our MSP and MSSP solutions and services benefitted the client in manu ways: Greater appeal to customers with high-level security and compliance needs, Lower data breach risk level and better security posture, Lighter workload for the internal staff member who used to handle all IT support tasks. He can now spend more time focusing on his other professional priorities.'
        },{
            subTitle: 'Technology Support',
            title1: 'Improve Application Delivery capabilities for an automabile giant',
            paragraph1: 'A large automobile manufacturer required an IT services partner to work flexibly to meet its immediate and evolving IT support needs. After talking to a number of companies in 2010, the customer quickly recognised Venturesoft Global\'s highly responsive, flexible and client-centric ethos and approached them with an urgent demand for 1st line support resources.',
            img1: 'assets/img/projects/img2.jpg',
            title2: 'Achievements',
            paragraph2: 'Our team enabled AEO\'s systems to recognize every customer with a single, reconciled customer profile. Empowered data stewards to comply with data provacy policies.',
            paragraph3: 'Following the formalisation of the service provider relationship, trust between the client and our team solidified. We initially delivered 1st line support, our scope of engagement has increased to include: Front line technology services for 1st & 2nd line support, with a dedicated, trained and tiered service team providing 24x7x365 support. This team is load balanced between California and Bengaluru, two of Venturesoft\'s three service centres. Scalable support and project resources to support client\'s strategic technology projects, including the roll out of services such as Office 365. Global support for McLaren\’s international operations led by and using Venturesoft\'s  global partner network',
            title3: 'Results',
            paragraph4: 'As a result of this engagament, the client was able to see improved operational efficiiency and was able to cut down the IT spend by 60% because of our offshoring capabilities.',
        }
    ]
    caseStudiesDetailsDescImportantFacts: ImportantFacts[] = [
        {
            facts: ['Statuses of IC, ICS, HUD through BOT','Vehicle ECE Testing','Vehicle Endurance Drives','Suppliers DVP&R report reviews','Configuration Management','Change Request and Bug Tracking'],
        },
        {
            facts: ['Data Unification','Semaless Consent Management','Artificially Intelligent Solution','Services Optimization','Customer Data','Dependable Privacy'],
        },
        {
            facts: ['Complex Contracts', 'Usage Data', 'Automated Pricing','Analysis and Reporting','Revenue Assurance Management','General Ledger Management'],
        },
        {
            facts: ['Dynamic Core','Risk Efficient','Risk Tolerance','Manage distributions','Municipal bonds Management','Transition management'],
        },
        {
            facts: ['Service Request Management','IT Asset Management','Incident Management','Problem Management','Change Management','Knoeldge management'],
        },
        {
            facts: ['Network Operations Center', 'Cunstomer Support','Level 1-3 IT Support','Project Management','Reduced Application Delivery cycles','Cloud Monitoring','Change Request and Bug Tracking'],
        },
    ]
    caseStudiesDetailsInfo: Info[] = [
        {
            icon: 'bx bx-user-pin',
            title: 'Client',
            subTitle: ['NIO', 'American Eagle Outfitters','A Large Billing Outsourcer','NY Based Investment Leader','A large Medical Devices Manufacturer','An automotive giant'],
        },
        {
            icon: 'bx bx-map',
            title: 'Location',
            subTitle: ['San Jose, CA', 'Pittsburg, PA', 'Atlanta, GA','New York, NY','Chicago, IL','Detroit, MI'],
        },
        {
            icon: 'bx bx-purchase-tag',
            title: 'Technologies',
            subTitle: ['Oracle SOA Suite, Data Validations', 'Salesforce cloud', 'Amdocs', 'AWS Cloud', 'TensorFlow, ML', 'Java/Javascript platforms'],
        },
        {
            icon: 'bx bx-check',
            title: 'Completed',
            subTitle: ['April 2018', 'June 2020', 'May 2017', 'Aug 2020' ,'July 2017' ,'Sep 2020'],
        },
        {
            icon: 'bx bx-globe',
            title: 'Website',
            subTitle: ['nio.com', 'ae.com', 'vstglobal.com', 'vstglobal.com','vstglobal.com','vstglobal.com'],
        }
    ]

}
class pageTitle {
    title: string;
    subTitle: string;
}
class Image {
    img: string;
}
class DetailsDesc {
    subTitle: string;
    title1: string;
    title2: string;
    title3: string;
    img1: string;
    paragraph1: string;
    paragraph2: string;
    paragraph3: string;
    paragraph4: string;
}
class ImportantFacts {
    facts: string[];
}
class Info {
    icon: string;
    title: string;
    subTitle: string[];
}