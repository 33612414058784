import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homethree-mission',
    templateUrl: './homethree-mission.component.html',
    styleUrls: ['./homethree-mission.component.scss']
})
export class HomethreeMissionComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    ourMissionImage: Image[] = [
        {
            img: 'assets/img/our-mission/img2.png'
        }
    ]
    ourMissionContent: Content[] = [
        {
            title: 'Employee Perks @ Venturesoft Global',
            paragraphText1: 'At Venturesoft Global, we believe in people.We believe that the most valuable resources an organization has are its human resources. And we believe in showing employees how valuable they are to the organization. ',
            paragraphText2: 'We offer the best employee benefits and perks and we have created a culture of appreciation within our organization. It’s a philosophy that’s not necessarily new—the idea that employees who are treated better perform better—but it’s starting to gain momentum in the professional world. At Venturesoft Global, we think that offering major benefits like flexible work schedules, generous PTO, and unique perks like a vacation reimbursement program (or a tuition reimbursement program), full cost for any IT certifications can do wonders for morale and productivity.',
            defaultBtnIcon: 'flaticon-right',
            defaultBtnText: 'Apply Now',
            defaultBtnLink: '/'
        }
    ]
    featuresList: List[] = [
        {
            icon: 'flaticon-tick',
            title: 'Flexible Work Schedule/Telecommuting'
        },
        {
            icon: 'flaticon-tick',
            title: 'Vacation / Paid Time Off'
        },
        {
            icon: 'flaticon-tick',
            title: 'Employee Discounts and Rewards'
        },
        {
            icon: 'flaticon-tick',
            title: 'Finding Balance'
        },
        {
            icon: 'flaticon-tick',
            title: 'Health Insurance / Wellness Program'
        }
    ]

}
class Image {
    img: string;
}
class Content {
    title: string;
    paragraphText1: string;
    paragraphText2: string;
    defaultBtnIcon: string;
    defaultBtnText: string;
    defaultBtnLink: string;
}
class List {
    icon: string;
    title: string;
}