import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-aboutpage-history',
    templateUrl: './aboutpage-history.component.html',
    styleUrls: ['./aboutpage-history.component.scss']
})
export class AboutpageHistoryComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: "Our History",
            title: 'History Begins in 1999',
            paragraphText: 'Venturesoft Global was founded and began software consulting services to major clients in the Bay area, CA, USA'
        }
    ]
    historyTimeline: TimelineBlock[] = [
        {
            year: '2006',
            date: 'February',
            title: 'New Bengaluru Offshore Center',
            paragraphText: 'Opened the offshore delivery center servicing major US clients from offshore',
            img: 'assets/img/history/img1.jpg'
        },
        {
            year: '2010',
            date: 'January',
            title: 'New Office in Japan',
            paragraphText: 'Opened the Venturesoft Global\'s Japan office, to focus on retail and automative companies in the Asia Pacific Region',
            img: 'assets/img/history/img2.jpg'
        },
        {
            year: '2016',
            date: 'March',
            title: 'Opened Office in the Middle East',
            paragraphText: 'Opened the Venturesoft Global\'s first middle east office in Dubai, to focus on government projects',
            img: 'assets/img/history/img3.jpg'
        },
        {
            year: '2018',
            date: 'December',
            title: '1000+ employees',
            paragraphText: 'Grew to 1000+ happy employees. Announced major employee development programs focusing on industry training, sustainability, business growth',
            img: 'assets/img/history/img4.jpg'
        }
    ]

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class TimelineBlock {
    year : string;
    date : string;
    title : string;
    paragraphText : string;
    img : string;
}