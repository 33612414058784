import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-process',
    templateUrl: './homeone-process.component.html',
    styleUrls: ['./homeone-process.component.scss']
})
export class HomeoneProcessComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: "How It Works",
            title: 'Our Data Science & Analytics Practice - Explained',
            paragraphText: 'Our teams have a proven track record of delivering tangible business outcomes globally and at scale, across industries and sectors'
        }
    ]
    singleProcessBox: processBoxContent[] = [
        {
            img: 'assets/img/process/img1.png',
            title: 'Understand the business',
            paragraphText: 'Understanding the business or activity that the data project is part of is key to ensuring its success and the first phase of any sound data analytics project',
            number: '1'
        },
        {
            img: 'assets/img/process/img2.png',
            title: 'Collect the Raw Data',
            paragraphText: 'Mixing and merging data from as many data sources as possible is what makes a data project great, so look as far as possible',
            number: '2'
        },
        {
            img: 'assets/img/process/img3.png',
            title: 'Process the Data',
            paragraphText: 'Using modern tools, start digging to see what data have been sourced and how to link everything together to achieve the original goals set for the organization',
            number: '3'
        },
        {
            img: 'assets/img/process/img4.png',
            title: 'Explore the Data',
            paragraphText: 'Merge all different sources and group logs to narrow your data down to the essential features',
            number: '4'
        },
        {
            img: 'assets/img/process/img5.png',
            title: 'Perform In-depth Analysis',
            paragraphText: 'Perform Descriptive, Diagnostic, Predictive and Prescriptive analysis of the data you collected',
            number: '5'
        },
        {
            img: 'assets/img/process/img6.png',
            title: 'Communicate Results',
            paragraphText: 'Visually present and highlight any gaps in the data or to flag any insights that might be open to interpretation',
            number: '6'
        }
    ]

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class processBoxContent {
    img : string;
    title : string;
    paragraphText : string;
    number : string;
}