import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-services',
    templateUrl: './homeone-services.component.html',
    styleUrls: ['./homeone-services.component.scss']
})
export class HomeoneServicesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: 'Our Services',
            paragraphText: 'Venturesoft Global\'s extensive software engineering expertise helps you launch new products faster, modernize existing products and maintain enhanced product portfolios at reduced costs, thereby delivering higher value for your customers. We enable software products to migrate to cloud based SaaS models, develop user friendly mobile apps and meet market specific customizations. Offer Professional Solutions For Business',
            title: 'We deliver results to any industry. Period.'
        }
    ]
    singleServicesBox: ServicesBoxContent[] = [
        {
            icon: 'assets/img/services/icon1.png',
            title: 'Banking Services',
            paragraphText: 'Ventuesoft Global offers comprehensive and customized solutions to organizations facing unprecedented scrutiny and pressure to serve as the first line of defense in the global war on money laundering and terrorist financing',
            link: 'services-details/banking',
            linkText: 'Read More',
            linkIcon: 'flaticon-right'
        },
        {
            icon: 'assets/img/services/icon2.png',
            title: 'AI & ML Development',
            paragraphText: 'Machine Algorithms we developed are running and influencing production and decision making for our clients today. Our skillsets range across a broad spectrum, which means we can engage meaningfully at every step.',
            link: 'services-details/ml',
            linkText: 'Read More',
            linkIcon: 'flaticon-right'
        },
        {
            icon: 'assets/img/services/icon3.png',
            title: 'UX/UI Modernization',
            paragraphText: 'With years of experience in website development and UI/UX Modernization, we have some core competencies to offer you, which will substantially improve the functioning of your websites and applications.',
            link: 'services-details/ux-ui',
            linkText: 'Read More',
            linkIcon: 'flaticon-right'
        },
        {
            icon: 'assets/img/services/icon4.png',
            title: 'Mobile Development',
            paragraphText: 'Our mobile app developers are trusted by some of the biggest Fortune 500 names in the world, and for good reason. We build awesome apps that are scalable, secure and truly reliable and quicker turn around to launch.',
            link: 'services-details/healthcare',
            linkText: 'Read More',
            linkIcon: 'flaticon-right'
        },
        {
            icon: 'assets/img/services/icon5.png',
            title: 'Software Development',
            paragraphText: 'We strive to deliver innovative, scalable, and competitive results. From fully managed Delivery Teams to individual expert software engineers, we specialize in solid end-to-end delivery of tailor-made technology solutions.',
            link: 'services-details/education',
            linkText: 'Read More',
            linkIcon: 'flaticon-right'
        },
        {
            icon: 'assets/img/services/icon6.png',
            title: 'RPA',
            paragraphText: 'With our Robotic Process Automation consulting services, we can help you identify automation opportunities and eliminate barriers to Automation. Our expertise range from Automation Anywhere, UiPath, Pega to Blue Prism ',
            link: 'services-details/rpa',
            linkText: 'Read More',
            linkIcon: 'flaticon-right'
        }
    ]
    loadMoreBtn: loadMore[] = [
        {
            link: 'services',
            linkText: 'Load More',
            linkIcon: 'flaticon-refresh'
        }
    ]

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class ServicesBoxContent {
    icon : string;
    title : string;
    paragraphText : string;
    link : string;
    linkText : string;
    linkIcon : string;
}
class loadMore {
    link : string;
    linkText : string;
    linkIcon : string;
}