

<section class="services-details-area pt-120">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="services-details-image" *ngFor="let DetailsImage of servicesDetailsImage;">
                    <img [src]="DetailsImage.img" alt="image">
                </div>

                <div class="services-details-desc">
                    <span class="sub-title">{{servicesDetailsDesc[currentStep].subTitle}}</span>
                    <h3>{{servicesDetailsDesc[currentStep].title1}}</h3>
                    <p>{{servicesDetailsDesc[currentStep].paragraphText1}}</p>

                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-6">
                            <div class="image">
                                <img [src]="servicesDetailsDesc[currentStep].img1" alt="image">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="content">
                                <h3>{{servicesDetailsDesc[currentStep].title2}}</h3>

                                <ul>
                                    <li *ngFor="let ImportantFacts of servicesDetailsDescImportantFacts[currentStep].title;">{{ImportantFacts}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <p>{{servicesDetailsDesc[currentStep].paragraphText2}}</p>

                    <!-- <h3>{{servicesDetailsDesc[currentStep].title3}}</h3>

                    <div class="row">
                        <div class="col-lg-4 col-sm-6 col-md-6" *ngFor="let Application of servicesDetailsDescApplication;">
                            <div class="single-industries-serve-box">
                                <div class="icon">
                                    <i class="{{Application.icon}}"></i>
                                </div>
                                
                                {{Application.title}}
                            </div>
                        </div>
                    </div> -->
                    
                    <h3>{{servicesDetailsDesc[currentStep].title4}}</h3>

                    <ul class="technologies-features">
                        <li *ngFor="let TechnologiesFeatures of servicesDetailsDescTechnologiesFeatures[currentStep].title;"><span>{{TechnologiesFeatures}}</span></li>
                    </ul>

                    <!-- <div class="charts-image">
                        <img [src]="servicesDetailsDesc[currentStep].img2" alt="image">
                    </div> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="services-details-info">
                    <ul class="services-list">
                        <li *ngFor="let ServicesList of sidebarServicesList;"><a (click) = "changeItem(ServicesList.item)">{{ServicesList.title}}</a></li>
                    </ul>

                    <!-- <div class="download-file">
                        <h3>Brochures</h3>

                        <ul>
                            <li *ngFor="let DownloadFile of sidebarDownloadFile;"><a routerLink="/{{DownloadFile.link}}">{{DownloadFile.title}} <i class='{{DownloadFile.icon}}'></i></a></li>
                        </ul>
                    </div> -->

                    <div class="services-contact-info">
                        <h3>Contact Info</h3>
                        
                        <ul>
                            <li *ngFor="let ContactInfo of sidebarContactInfo;">
                                <div class="icon">
                                    <i class='{{ContactInfo.icon}}'></i>
                                </div>
                                <span>{{ContactInfo.title}}:</span>
                                {{ContactInfo.subTitle}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <app-related-services></app-related-services> -->