<section class="page-title-area">
    <div class="container">
        <div class="page-title-content"
             *ngFor="let pageTitle of pageTitleArea;">
            <h2>{{pageTitle.title}}</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{pageTitle.title}}</li>
            </ul>
        </div>
    </div>

    <div class="shape-img1"><img src="assets/img/shape/shape1.svg"
             alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.png"
             alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png"
             alt="image"></div>
</section>

<section class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="contact-info-box"
                     *ngFor="let InfoBox1 of contactInfoBox1;">
                    <div class="back-icon">
                        <i class='{{InfoBox1.icon}}'></i>
                    </div>
                    <div class="icon">
                        <i class='{{InfoBox1.icon}}'></i>
                    </div>
                    <h3>{{InfoBox1.title}}</h3>
                    <p>{{InfoBox1.location}}</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="contact-info-box"
                     *ngFor="let InfoBox2 of contactInfoBox2;">
                    <div class="back-icon">
                        <i class='{{InfoBox2.icon}}'></i>
                    </div>
                    <div class="icon">
                        <i class='{{InfoBox2.icon}}'></i>
                    </div>
                    <h3>Contact Number</h3>
                    <p>Phone: <a href="tel:+19253535023">+1 925-353-5023</a></p>
                    <p>Fax: <a href="tel:+19258852444">+1 925-885-2444</a></p>
                </div>
            </div>
        </div>
        <!-- <div class="row  mb-2">
            <div class="col-lg-6 col-md-6">
                <div class="contact-info-box"
                     *ngFor="let InfoBox1 of contactInfoBox1;">
                    <div class="back-icon">
                        <i class='{{InfoBox1.icon}}'></i>
                    </div>
                    <div class="icon">
                        <i class='{{InfoBox1.icon}}'></i>
                    </div>
                    <h3>Our East Coast Office</h3>
                    <p>9711 Washington Blvd Suite 550, Gaithersburg, MD 20878
                    </p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="contact-info-box"
                     *ngFor="let InfoBox2 of contactInfoBox2;">
                    <div class="back-icon">
                        <i class='{{InfoBox2.icon}}'></i>
                    </div>
                    <div class="icon">
                        <i class='{{InfoBox2.icon}}'></i>
                    </div>
                    <h3>Contact Number</h3>
                    <p>Phone: <a href="tel:+19253535023">+1 925-353-5023</a></p>
                    <p>Fax: <a href="tel:+19258852444">+1 925-885-2444</a></p>
                </div>
            </div>
        </div> -->
        <div class="row  mb-2">
            <div class="col-lg-6 col-md-6">
                <div class="contact-info-box"
                     *ngFor="let InfoBox1 of contactInfoBox1;">
                    <div class="back-icon">
                        <i class='{{InfoBox1.icon}}'></i>
                    </div>
                    <div class="icon">
                        <i class='{{InfoBox1.icon}}'></i>
                    </div>
                    <h3>Our Canada Office</h3>
                    <p>777 Hornby Street, Suite 600, Vancouver, BC V6Z 1S4, Canada</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="contact-info-box"
                     *ngFor="let InfoBox2 of contactInfoBox2;">
                    <div class="back-icon">
                        <i class='{{InfoBox2.icon}}'></i>
                    </div>
                    <div class="icon">
                        <i class='{{InfoBox2.icon}}'></i>
                    </div>
                    <h3>Contact Number</h3>
                    <p>Phone: <a href="tel:+19253535023">+1 925-353-5023</a></p>
                    <p>Fax: <a href="tel:+19258852444">+1 925-885-2444</a></p>
                </div>
            </div>
        </div>


        <div class="row  mb-2">
            <div class="col-lg-6 col-md-6">
                <div class="contact-info-box"
                     *ngFor="let InfoBox1 of contactInfoBox1;">
                    <div class="back-icon">
                        <i class='{{InfoBox1.icon}} '></i>
                    </div>
                    <div class="icon">
                        <i class='{{InfoBox1.icon}}'></i>
                    </div>
                    <h3>Our Bengaluru Office</h3>
                    <p>Venture SoftTech India PVT LTD
                        Novel Tech Park, 1st floor
                        46/4, Garvebavi Palya,
                        Hosur Main Road,
                        Kudlu Gate, Electronic City,
                        Bengaluru - 560068</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="contact-info-box"
                     *ngFor="let InfoBox2 of contactInfoBox2;">
                    <div class="back-icon">
                        <i class='{{InfoBox2.icon}}'></i>
                    </div>
                    <div class="icon">
                        <i class='{{InfoBox2.icon}}'></i>
                    </div>
                    <h3>Contact Number</h3>
                    <p>Phone: <a href="tel:+918041705135">+91 80 4170 5135</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="contact-area pb-100">
    <div class="container">
        <div class="section-title"
             *ngFor="let sectionTitleContent of sectionTitle;">
            <span class="sub-title"><img src="assets/img/star-icon.png"
                     alt="image"> {{sectionTitleContent.subTitle}}</span>
            <h2>{{sectionTitleContent.title}}</h2>
            <p>{{sectionTitleContent.paragraphText}}</p>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-image"
                     *ngFor="let Image of contactImage;">
                    <img [src]="Image.img"
                         alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="contact-form">
                    <form class="login-form mt-4"
                          [formGroup]="contactForm"
                          (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input type="text"
                                           name="name"
                                           class="form-control"
                                           id="name"
                                           formControlName="name"
                                           placeholder="Your name"
                                           [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                    <div *ngIf="submitted && f.name.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="f.name.errors.required">Name
                                            is required</div>
                                    </div>
                                </div>

                            </div>

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input type="email"
                                           name="email"
                                           class="form-control"
                                           id="email"
                                           formControlName="email"
                                           placeholder="Your email address"
                                           [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                    <div *ngIf="submitted && f.email.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">
                                            Valid Email is required</div>
                                    </div>
                                </div>

                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input type="text"
                                           name="phone_number"
                                           class="form-control"
                                           [mask]="phonemask"
                                           id="phone_number"
                                           placeholder="Your phone number"
                                           formControlName="phone_number"
                                           [ngClass]="{ 'is-invalid': submitted && f.phone_number.errors }">
                                    <div *ngIf="submitted && f.phone_number.errors"
                                         class="invalid-feedback">
                                        <div
                                             *ngIf="f.phone_number.errors.required">
                                            Phone Number is required</div>
                                    </div>
                                </div>

                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message"
                                              id="message"
                                              class="form-control"
                                              cols="30"
                                              formControlName="message"
                                              rows="6"
                                              placeholder="Write your message..."
                                              [ngClass]="{ 'is-invalid': submitted && f.message.errors }"></textarea>
                                    <div *ngIf="submitted && f.message.errors"
                                         class="invalid-feedback">
                                        <div *ngIf="f.message.errors.required">
                                            Message is required</div>
                                    </div>
                                </div>

                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit"
                                        class="default-btn"><i
                                       class="flaticon-tick"></i>Send
                                    Message<span></span></button>
                                <div *ngIf="showMsg"
                                     class="text-success mt-2">
                                    <div>{{successMsg}}</div>
                                </div>
                            </div>


                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<div id="map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3158.4472202402912!2d-121.90020178468556!3d37.662197979780125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fe981dc37134b%3A0x120cef1963371413!2s7401%20Koll%20Center%20Pkwy%2C%20Pleasanton%2C%20CA%2094566!5e0!3m2!1sen!2sus!4v1613012426438!5m2!1sen!2sus"
            width="600"
            height="450"
            frameborder="0"
            style="border:0;"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"></iframe>
</div>
