import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-homethree-about",
  templateUrl: "./homethree-about.component.html",
  styleUrls: ["./homethree-about.component.scss"],
})
export class HomethreeAboutComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  aboutImage: Image[] = [
    {
      img: "assets/img/about/img4.png",
    },
  ];
  aboutContent: Content[] = [
    {
      title: "Employee Referral Program",
      paragraphText1:
        "We will pay $1,500.00 for any referral by an employee for any candidate hired for the below positions. Venturesoft Global (Pleasanton, CA) has multiple positions for:",
      paragraphText2:
        "Travel and/or relocate to multiple unanticipated client locations throughout the USA.",
      paragraphText3:
        "Send CV to: Venturesoft Global Inc. 5000 Hopyard Rd, Suite 120 Pleasanton, CA 94588.",
      defaultBtnIcon: "flaticon-right",
      defaultBtnText: "More About Us",
      defaultBtnLink: "about-us",
    },
  ];
  contentList: List[] = [
    {
      icon: "flaticon-tick",
      title: "Sr Systems Analyst",
    },
    {
      icon: "flaticon-tick",
      title: "Software Engineer",
    },
  ];
}
class Image {
  img: string;
}
class Content {
  title: string;
  paragraphText1: string;
  paragraphText2: string;
  paragraphText3: string;
  defaultBtnIcon: string;
  defaultBtnText: string;
  defaultBtnLink: string;
}
class List {
  icon: string;
  title: string;
}
