<div class="navbar-area">
    <div class="vst-responsive-nav">
        <div class="container">
            <div class="vst-responsive-menu">
                <div class="logo">
                    <a routerLink="/">
                        <img src="assets/img/logo.svg" alt="logo">
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="vst-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/logo.svg" alt="logo">
                </a>

                <div class="collapse navbar-collapse mean-menu">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a routerLink="/about-us" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li>
                        
                        <li class="nav-item"><a routerLink="/"  class="nav-link">Industries <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a [routerLink]="['/services-details','banking' ]" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Banking</a></li>
                                <li class="nav-item"><a [routerLink]="['/services-details','insurance' ]"  class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Healthcare</a></li>
                                <li class="nav-item"><a [routerLink]="['/services-details','telecom' ]" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Telecom</a></li>
                                
                                <li class="nav-item"><a [routerLink]="['/services-details','security' ]" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Network & Security</a></li>
                                <li class="nav-item"><a [routerLink]="['/services-details','insurance' ]"  class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Insurance Domains</a></li>
                                <li class="nav-item"><a [routerLink]="['/services-details','manufacturing' ]"  class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Manufacturing</a></li>
                                <li class="nav-item"><a [routerLink]="['/services-details','education' ]"  class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Education</a></li>
                                
                            </ul>
                        </li>
                        <li class="nav-item"><a routerLink="/"  class="nav-link">Specializations <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                
                                <li class="nav-item"><a [routerLink]="['/services-details','sap' ]" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">SAP Practice</a></li>
                                <li class="nav-item"><a [routerLink]="['/services-details','cloud' ]" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Cloud Solutions</a></li>
                                <li class="nav-item"><a [routerLink]="['/services-details','datascience' ]" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Data Science</a></li>
                                <li class="nav-item"><a [routerLink]="['/services-details','ml' ]" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Machine Learning</a></li>
                                <li class="nav-item"><a [routerLink]="['/services-details','startups' ]" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">StartUps</a></li>
                                <li class="nav-item"><a [routerLink]="['/services-details','rpa' ]"   class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Robotic Process Automation</a></li>
                                <li class="nav-item"><a [routerLink]="['/services-details','ux-ui' ]"   class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">UX/UI Modernization</a></li>

                                </ul>
                        </li>


                        <li class="nav-item"><a routerLink="/case-studies" class="nav-link">Case Studies</a>
                        </li>


                        <li class="nav-item"><a routerLink="/careers" class="nav-link">Careers</a>
                        </li>
                    </ul>

                    <div class="others-option d-flex align-items-center">

                        <div class="option-item">
                            <a routerLink="/contact" class="default-btn"><i class="flaticon-right"></i>Contact Us<span></span></a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <div class="others-option-for-responsive">
        <div class="container">
            
            
            <div class="container">
                <div class="option-inner">
                    <div class="others-option">
                        <!-- <div class="option-item">
                            <form class="search-box">
                                <input type="text" class="input-search" placeholder="Search for anything">
                                <button type="submit"><i class="flaticon-loupe"></i></button>
                            </form>
                        </div> -->

                        <div class="option-item">
                            <a routerLink="/contact" class="default-btn"><i class="flaticon-right"></i>Contact Us<span></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>