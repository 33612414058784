<footer class="{{footerClass}}">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="assets/img/logo.svg" alt="logo"></a>
                    <p>VentureSoft Global is a recognized leader and trusted partner to Global 1000 businesses. We provide our customers immediate and sustained value in Data Science, IoT, Big Data, Analytics, Information Technology strategy and execution, Business Process Optimization and Knowledge transfer.</p>
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Explore</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="">Home</a></li>
                        <li><a routerLink="/about-us">About</a></li>
                        <li><a routerLink="/case-studies">Case Studies</a></li>
                        <li><a routerLink="/careers">Careers</a></li>
                        <li><a routerLink="/contact">Contact</a></li>
                    </ul>
                </div>
            </div>

            <!-- <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Resources</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/team">Our Team</a></li>
                        <li><a routerLink="/services">Our Services</a></li>
                        <li><a routerLink="/">Testimonials</a></li>
                        <li><a routerLink="/services-details">SaaS Solutions</a></li>
                        <li><a routerLink="/services-details">eCommerce</a></li>
                    </ul>
                </div>
            </div> -->

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Address</h3>
                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i>5000 Hopyard Rd
                            Suite 120, Pleasanton, CA 94588</li>
                        <li><i class='bx bx-phone-call'></i><a href="tel:+19253535023">+1 925-353-5023</a></li>
                        <li><i class='bx bx-envelope'></i><a href="mailto:info@vstglobal.com">info@vstglobal.com</a></li>
                        <li><i class='bx bxs-inbox'></i><a href="tel:+19258852444">+1 925-885-2444</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p><i class='bx bx-copyright'></i>2021 <strong>VST Global Inc.</strong> All rights reserved</p>
                </div>

                <!-- <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Privacy Policy</a></li>
                        <li><a routerLink="/">Terms & Conditions</a></li>
                    </ul>
                </div> -->
            </div>
        </div>
    </div>

    <div class="footer-map"><img src="assets/img/footer-map.png" alt="image"></div>
</footer>

<div class="go-top"><i class="flaticon-up"></i></div>