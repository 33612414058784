import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homethree-featured-services',
    templateUrl: './homethree-featured-services.component.html',
    styleUrls: ['./homethree-featured-services.component.scss']
})
export class HomethreeFeaturedServicesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    singleFeaturedServicesItem: FeaturedServicesItem[] = [
        {
            icon: 'assets/img/icon/icon1.png',
            title: 'Amazing Mentorship',
            paragraphText: 'Never stop learning by getting an online or in-person mentoring on ANY topic you want to grow in. So, create an exciting journey with us.',
            btnIcon: 'flaticon-right',
            btnText: 'Read More',
            link: 'services-details'
        },
        {
            icon: 'assets/img/icon/icon2.png',
            title: 'Continuous Learning',
            paragraphText: 'Without any doubt, employees can update the skills to perform their jobs. VST Global has an amazing platform to keep your skills updated while on the job',
            btnIcon: 'flaticon-right',
            btnText: 'Read More',
            link: 'services-details'
        },
        {
            icon: 'assets/img/icon/icon3.png',
            title: 'Fast Paced Career Path',
            paragraphText: 'Fast-paced learning is what drives our engineering culture. So team gets to learn fast with learning program, learning perks and clear career path.',
            btnIcon: 'flaticon-right',
            btnText: 'Read More',
            link: 'services-details'
        }
    ]

}
class FeaturedServicesItem {
    icon : string;
    title : string;
    paragraphText : string;
    btnIcon : string;
    btnText : string;
    link : string;
}