import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-case-studies-three-columns-page',
    templateUrl: './case-studies-three-columns-page.component.html',
    styleUrls: ['./case-studies-three-columns-page.component.scss']
})
export class CaseStudiesThreeColumnsPageComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Case Studies'
        }
    ]
    singleProjectsBox: singleProjectsContent[] = [
        {
            icon: 'bx bx-plus',
            img: 'assets/img/projects/img1.jpg',
            title: 'Digital Cockpit Validation',
            subTitle: 'NIO - Electric Vehicles',
            link: 'case-studies-details/nio'
        },
        {
            icon: 'bx bx-plus',
            img: 'assets/img/projects/img2.jpg',
            title: 'Master Data Consolidation',
            subTitle: 'American Eagle Outfitters',
            link: 'case-studies-details/aeo'
        },
        {
            icon: 'bx bx-plus',
            img: 'assets/img/projects/img3.jpg',
            title: 'Billing Operations',
            subTitle: 'BPO Client',
            link: 'case-studies-details/bpo'
        },
        {
            icon: 'bx bx-plus',
            img: 'assets/img/projects/img4.jpg',
            title: 'Investment Management',
            subTitle: 'Operations',
            link: 'case-studies-details/inv'
        },
        {
            icon: 'bx bx-plus',
            img: 'assets/img/projects/img5.jpg',
            title: 'IT services Management',
            subTitle: 'Pharmaceutical and Medical Devices',
            link: 'case-studies-details/med'
        },
        {
            icon: 'bx bx-plus',
            img: 'assets/img/projects/img6.jpg',
            title: 'Technology Support',
            subTitle: 'Automotive Industry',
            link: 'case-studies-details/ts'
        }
    ]

}
class pageTitle {
    title : string;
}
class singleProjectsContent {
    icon : string;
    img : string;
    title : string;
    subTitle : string;
    link : string;
}