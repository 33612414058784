import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homethree-banner',
    templateUrl: './homethree-banner.component.html',
    styleUrls: ['./homethree-banner.component.scss']
})
export class HomethreeBannerComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    heroBannerContent: Content[] = [
        {
            title: 'Work & be Yourself',
            paragraphText: 'Learning, growth and work-life balance are foundation blocks of work culture at Venturesoft Global. This is because workforce satisfaction forms an integral aspects of organization values. A good culture can drive you to become an extraordinary professional.',
            defaultBtnIcon: 'flaticon-right',
            defaultBtnText: 'Explore Oppurtunities',
            defaultBtnLink: 'events'
        }
    ]

}
class Content {
    title : string;
    paragraphText : string;
    defaultBtnIcon : string;
    defaultBtnText: string;
    defaultBtnLink : string;
}