import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-case-studies',
    templateUrl: './homeone-case-studies.component.html',
    styleUrls: ['./homeone-case-studies.component.scss']
})
export class HomeoneCaseStudiesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    singleCaseStudyItem: singleCaseStudyItemContent[] = [
        {
            subTitle: 'Case study #1',
            title: 'Marketing Data Analytics',
            paragraphText1: 'Provide consolidated, common data foundation, data process for reporting of channel metrics to marketing operations',
            paragraphText2: 'VentureSoft’s business data, error reporting & business intelligence (Data & ERBI) initiative laid a common data foundation, framework and standards. Data for 360 degree customer, prospect view for segmentation and targeting including personalization and customization',
            link: 'case-studies-details',
            linkText: 'Details More',
            linkIcon: 'flaticon-view',
            img: 'assets/img/case-study/img1.jpg'
        },
        {
            subTitle: 'Case study #2',
            title: 'Anamoly Detection',
            paragraphText1: 'Integrate Braintree’s data into Paypal’s ecosystem. Anomaly Detection – Data Mapping and Analysis of Payment gateway transactions. ICS, HUD & talking BOT',
            paragraphText2: 'Data Science techniques - Machine Learning techniques for identifying outliers, temporal changes, level shifts etc. Used techniques like Time series forecasting, Exponential Smoothing, ARIMA etc. Also built a clustering based Anomaly detection model to identify anomalies in different groups of data.',
            link: 'case-studies-details',
            linkText: 'Details More',
            linkIcon: 'flaticon-view',
            img: 'assets/img/case-study/img2.jpg'
        }
    ]

}
class singleCaseStudyItemContent {
    subTitle : string;
    title : string;
    paragraphText1 : string;
    paragraphText2 : string;
    link : string;
    linkText : string;
    linkIcon : string;
    img : string;
}