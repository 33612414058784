import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-solutions',
    templateUrl: './solutions.component.html',
    styleUrls: ['./solutions.component.scss']
})
export class SolutionsComponent implements OnInit {

    constructor(private route: Router) { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: 'Our Strengths',
            title: 'We are unique in our own way',
            paragraphText: 'VentureSoft Global is a distinct leader in delivering technology-driven enterprise solutions satisfying the needs of global clients by helping them achieve paradigm shifts in the way they run their business. VentureSoft Global delivers unmatched value to its customers by helping them transform their operations and not simply maintain a ‘status-quo’ through delivery innovation.'
        }
    ]
    singleSolutionsBox: solutionsBoxContent[] = [
        {
            icon: 'flaticon-settings',
            title: 'Security & Network',
            paragraphText: 'Our senior technicians can create an IT strategy that detects, monitors and corrects your current issues and future-proofs your network environment to achieve long-term security and optimal performance.',
            link: 'services-details',
            linkText: 'View Details',
            type:'security'
        },
        {
            icon: 'flaticon-money',
            title: 'Financial Services',
            paragraphText: 'Venturesoft Global believes in a collaborative approach and delivers co-innovation lab offerings to banking sector clients. Our talented professionals work with emergent technologies for applied innovation.',
            link: 'services-details',
            linkText: 'View Details',
            type:'banking'
        },
        {
            icon: 'flaticon-hospital',
            title: 'Health Care',
            paragraphText: 'VST Global is always at the cutting edge of the quest to improve on best practices in healthcare IT consulting services. Our healthcare services team have helped clients achieve goals while maximizing their Return on Investment (RoI).',
            link: 'services-details',
            linkText: 'View Details',
            type:'healthcare'
        }
    ]

    goToServices(item) {
        this.route.navigate([item.link], { queryParams: { type: item.type } })
    }

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class solutionsBoxContent {
    icon : string;
    title : string;
    paragraphText : string;
    link : string;
    linkText : string;
    type: string;
}