<section class="projects-area pt-120 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let singleProjectsContent of singleProjectsBox;">
                <div class="single-projects-box">
                    <div class="image">
                        <img [src]="singleProjectsContent.img" alt="image">

                        <a routerLink="/{{singleProjectsContent.link}}" class="link-btn"><i class='{{singleProjectsContent.icon}}'></i></a>
                    </div>

                    <div class="content">
                        <h3><a routerLink="/{{singleProjectsContent.link}}">{{singleProjectsContent.title}}</a></h3>
                        <span>{{singleProjectsContent.subTitle}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>