import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {
    contactForm: FormGroup;
    submitted = false;
    showMsg = false;
    phonemask = '(000) 000-0000'
    successMsg = 'Thanks for contacting us!'
    constructor(private formBuilder: FormBuilder) { }

    ngOnInit(): void {
        this.contactForm = this.formBuilder.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            phone_number: ['', Validators.required],
            message: ['', [Validators.required]]
        });
    }
    get f() { return this.contactForm.controls; }

    onSubmit() {
        this.showMsg = false;
        this.submitted = true;
        // stop here if form is invalid
        if (this.contactForm.invalid) {
            return;
        }
        this.showMsg = true;
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Contact Us'
        }
    ]
    contactInfoBox1: InfoBox1[] = [
        {
            icon: 'bx bx-map',
            title: 'Our Head-Quarters',
            location: '5000 Hopyard Rd, Suite 120 Pleasanton, CA 94588.'
        }
    ]
    contactInfoBox2: InfoBox1[] = [
        {
            icon: 'bx bx-phone',
            title: 'Our East Coast Office',
            location: '9711 Washington Blvd, Suite 550 Gaithersburg, MD 20878.'
        }
    ]
    contactInfoBox3: InfoBox1[] = [
        {
            icon: 'bx bx-map',
            title: 'Our Bengaluru Office',
            location: 'Sourabha Complex, #5, AM, Industrial Estate, 12th KM stone, Hosur Road,Bangalore, India 560069.'
        }
    ]

   
    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: "Get In Touch",
            title: 'Ready to Get Started?',
            paragraphText: 'Your email address will not be published. Required fields are marked *'
        }
    ]
    contactImage: Image[] = [
        {
            img: 'assets/img/contact.png'
        }
    ]

}
class pageTitle {
    title : string;
}
class InfoBox1 {
    icon : string;
    title : string;
    location : string;
}
class InfoBox2 {
    icon : string;
    title : string;
    number : string;
    email : string;
}
class InfoBox3 {
    icon : string;
    title : string;
    text1 : string;
    text2 : string;
}

class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class Image {
    img : string;
}