import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homefour-testimonials',
    templateUrl: './homefour-testimonials.component.html',
    styleUrls: ['./homefour-testimonials.component.scss']
})
export class HomefourTestimonialsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            title: 'What Our Learners are Saying?',
            paragraphText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.'
        }
    ]
    singleTestimonialsBox: TestimonialsBoxContent[] = [
        {
            clientImg: 'assets/img/testimonials/img1.jpg',
            paragraphText: 'VST team was open, flexible, and collaborative. Fresh told us what we needed, and worked with us on a tight timeline. The team provided lots of great ideas regarding the structure of the development process, creating good proposals and working with our team to better define and refine. We are extremely impressed with quality of deliverables.',
            clientName: 'Enerson, Todd',
            clientDesignation: 'Senior Manager, Intuit'
        },
        {
            clientImg: 'assets/img/testimonials/img2.jpg',
            paragraphText: 'We were overwhelmed with the collaboration, dedication, and insightful consultation we received from VST Global throughout the project implementation.  VST Team\'s behind-the-scenes consultants really helped refine and improve the overall quality of our final digital cockpit solutions.',
            clientName: 'Iyer, Ganesh',
            clientDesignation: 'Executive Director, NIO'
        },
        {
            clientImg: 'assets/img/testimonials/img3.jpg',
            paragraphText: 'We began working with VST Global because we needed a right partner in our IT journey. Venturesoft Global helped navigte a lot of challenges within our organization. Truly a great IT partner to have a long time relationship.',
            clientName: 'Dommathamari, Harish',
            clientDesignation: 'Senior Manager, Arista Networks'
        }
    ]
    testimonialsBtn: Btn[] = [
        {
            link: "/",
            icon: 'flaticon-view',
            text: 'Check Out All Reviews'
        }
    ]

}
class sectionTitleContent {
    title : string;
    paragraphText : string;
}
class TestimonialsBoxContent {
    clientImg : string;
    paragraphText : string;
    clientName : string;
    clientDesignation : string;
}
class Btn {
    link : string;
    icon : string;
    text : string;
}