import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-testimonials',
    templateUrl: './homeone-testimonials.component.html',
    styleUrls: ['./homeone-testimonials.component.scss']
})
export class HomeoneTestimonialsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: "Testimonials",
            title: 'What Our Clients Are Saying?',
            paragraphText: 'Our clients call us trusted advisors. We call them inspirational teammates. See why we enjoy working together—and the impact our relationships create'
        }
    ]
    singleTestimonialsItem: TestimonialsItemContent[] = [
        {
            clientImg: 'assets/img/testimonials/img1.jpg',
            paragraphText: 'VST team was open, flexible, and collaborative. The team provided lots of great ideas regarding the structure of the development process, creating good proposals and working with our team to better define and refine.',
            clientName: 'Enerson, Todd',
            clientDesignation: 'Senior Manager, Intuit'
        },
        {
            clientImg: 'assets/img/testimonials/img2.jpg',
            paragraphText: 'We were overwhelmed with the collaboration, dedication, and insightful consultation we received from VST Global.  Their consultants really helped refine and improve the overall quality of our final digital cockpit solutions',
            clientName: 'Iyer, Ganesh',
            clientDesignation: 'Executive Director, NIO'
        },
        {
            clientImg: 'assets/img/testimonials/img3.jpg',
            paragraphText: 'We began working with VST Global because we needed a right partner in our IT journey. Venturesoft Global helped navigte a lot of challenges within our organization. Truly a great IT partner to have a long time relationship.”',
            clientName: 'Dommathamari, Harish',
            clientDesignation: 'Senior Manager, Arista Networks'
        }
    ]
    testimonialsBtn: Btn[] = [
        {
            link: "/",
            icon: 'flaticon-view',
            text: 'Check Out All Reviews'
        }
    ]

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class TestimonialsItemContent {
    paragraphText : string;
    clientImg : string;
    clientName : string;
    clientDesignation : string;
}
class Btn {
    link : string;
    icon : string;
    text : string;
}