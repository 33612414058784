import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-services-details-page',
    templateUrl: './services-details-page.component.html',
    styleUrls: ['./services-details-page.component.scss']
})
export class ServicesDetailsPageComponent implements OnInit {

    currentStep = 0;
    pages = ['security', 'healthcare', 'banking', 'datascience','ml','cloud','ux-ui','insurance','manufacturing','telecom','education','startups','rpa', 'sap'];
    constructor(
        public route: ActivatedRoute,) { 
            this.route.queryParams.subscribe(params => {
                this.currentStep = this.pages.indexOf(this.route.snapshot.paramMap.get('type'))
            })
        }

    ngOnInit(): void {
        
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Services',
            subTitle: 'Network and Security',
        },
        {
            title: 'Services',
            subTitle: 'Healthcare',
        },
        {
            title: 'Services',
            subTitle: 'Banking & Financial Services',
        },
        {
            title: 'Services',
            subTitle: 'Data Science',
        },
        {
            title: 'Services',
            subTitle: 'Machine Learning',
        },
        {
            title: 'Services',
            subTitle: 'Cloud Solutions',
        },
        {
            title: 'Services',
            subTitle: 'UX/UI Modernization',
        },
        {
            title: 'Services',
            subTitle: 'Insurance',
        },
        {
            title: 'Services',
            subTitle: 'Manufacturing',
        }, {
            title: 'Services',
            subTitle: 'Telecom',
        },
        {
            title: 'Services',
            subTitle: 'Education',
        },
        {
            title: 'Services',
            subTitle: 'Digital Startups',
        },
        {
            title: 'Services',
            subTitle: 'Robotic Process Automation',
        },
        {
            title: 'Services',
            subTitle: 'SAP Practice',
        }
    ]
    servicesDetailsImage: DetailsImage[] = [
        {
            img: 'assets/img/services/services-details1.jpg'
        }
    ]
    servicesDetailsDesc: DetailsDesc[] = [
        {
            subTitle: 'Security & Network',
            title1: 'Build Robust Security and Network Infrastructure',
            paragraphText1: 'Network & security isn’t only about IT. It’s about expanding beyond organizational walls into operations and products. Observing regulatory security and compliance policies are vital for every organization. Sensitive data is always at risk of being compromised. To minimize that risk, it’s pertinent to have the proper policies and training in place.',
            paragraphText2: 'We know that security and compliance go hand-in-hand, yet many organizations find it hard to deploy, maintain, and protect their technology. Regulatory compliance isn’t always easy to understand, but we work with you to help maintain adherence to applicable standards, codes, regulations, and laws. In the event of a willful or accidental data breach, lawsuits from affected parties, or steep government fines, non-compliance can cost your organization everything.',
            title2: 'Important Facts',
            title3: 'Segments',
            title4: 'Technologies That We Use',
            img1: 'assets/img/projects/img2.jpg',
            img2: 'assets/img/services/charts.jpg'
        },
        {
            subTitle: 'Healthcare',
            title1: 'Transform Patient Care Experiences',
            paragraphText1: 'With managing health outcomes as central theme, healthcare organizations are striving to drive synergies in cost reduction, operational efficiency and delivery of care. However, for healthcare organizations to realize the triple aim, it is important to address inherent challenges of modernizing technology systems that have grown in an unstructured manner over a period of time. The challenges for healthcare consulting also include the need for integration of disparate systems, process and people along with optimization of interaction/touch points to enable seamless communication between different stakeholders.',
            paragraphText2: 'As a technology consulting firm in healthcare consulting, Venturesoft Global is always at the cutting edge of the quest to continuously improve on best practices in healthcare IT consulting services. Our enterprise medical services have helped our customer to meet their Triple Aim goals while maximizing their Return on Investment (RoI).',
            title2: 'Important Facts',
            title3: 'Healthcare Segments',
            title4: 'Solutions we provide',
            img1: 'assets/img/projects/img2.jpg',
            img2: 'assets/img/services/charts.jpg'
        },
        {
            subTitle: 'Banking & Financial Services',
            title1: 'Future-Ready Digital Banking Solutions',
            paragraphText1: 'Banking and financial institutions are constantly competing with altering customer expectations. Today, adopting digitally sound environments and offerings are only half the battle won. With Venturesoft Global\’s digital banking services, achieve end-to-end transformation for all your banking needs while providing superior customer experiences and maximizing operational efficiencies.',
            paragraphText2: 'Corporate banking customers demand real-time interaction, service immediacy, and transparency. They want an experience on par with that of Uber or Amazon in corporate banking. With their dominance under threat, retail banking and corporate banking and commercial lending organizations are embarking on a trajectory for change.',
            title2: 'Specializations',
            title3: 'Financial Domains',
            title4: 'Technologies That We Use',
            img1: 'assets/img/projects/img2.jpg',
            img2: 'assets/img/services/charts.jpg'
        },
        
          
          {
            subTitle: 'Data Science',
            title1: 'Access to Enterprise-Class Advanced Analytics',
            paragraphText1: 'A data science capability embeds and operationalizes data science across an enterprise such that it can deliver the next level of organizational performance and return on investment. A data science capability moves an organization beyond performing pockets of analytics to an enterprise approach that uses analytical insights as part of the normal course of business.',
            paragraphText2: 'Our services help organizations  use data and analytics to create new business models and revenue streams – all while ensuring security, quality and regulatory compliance of data. Underpinned by technologies such as cloud, Internet of Things (IoT), Artificial Intelligence (AI), Machine Learning (ML) and advanced analytics, our solutions help enhance decision making while enabling augmented intelligence and process automation. In addition, our vast experiences help secure innovation and scale programs to deliver tangible results.',
            title2: 'Services We Perform',
            title3: 'Healthcare Segments',
            title4: 'Technologies That We Use',
            img1: 'assets/img/projects/img2.jpg',
            img2: 'assets/img/services/charts.jpg'
          },
          {
            subTitle: 'Machine Learning',
            title1: 'The DNA of better decision-making',
            paragraphText1: 'In today’s business environment, making the right decisions or course corrections at the right time can make all the difference. This requires a fast, thorough analysis of extremely complex situations to evaluate all potential pathways to each outcome and map the ideal strategies for getting there. Imagine how many data scientists that would take and how long?',
            paragraphText2: 'Our Machine Learning team uses advanced evolutionary algorithms and deep learning to produce actionable results from complicated, multivariate problems. Our capability in evaluating millions of variables against business goals, every option weighed for its benefit and the very best path to success identified in a very short period of time adds value to any business.',
            title2: 'Our Advantages',
            title3: 'Healthcare Segments',
            title4: 'Technologies That We Use',
            img1: 'assets/img/projects/img2.jpg',
            img2: 'assets/img/services/charts.jpg'
          },
          {
            subTitle: 'Cloud Solutions',
            title1: 'Leverage Cloud for Optimization',
            paragraphText1: 'In this age of rapid digitization, with agility as the Holy Grail, organizations are racing for a major upgrade by adopting cloud services in every possible facet. As a global technology services firm, leveraging our technical expertise and treasured partnership with AWS and Microsoft Azure, Venturesoft Global has devised a robust cloud adoption strategy. Our 5-fold solution suite to create a streamlined and holistic approach to our customers’ cloud initiatives consists of an extensive cloud consultation, futuristic transformation solutions, innovative cloud engineering practices, cost-efficient optimization solutions and a solid cloud infrastructure management structure.',
            paragraphText2: 'Venturesoft Global helps organizations transform their IT environment with best-in-class Cloud Computing Services. Our team of experts delivers highly-effective and reliable cloud computing services that provide organizations with a competitive edge. Our cloud solutions enable organizations to reduce IT resource requirements and improve productivity, in addition to lowering costs and reducing the time-to-market. We assist customers with our on-demand delivery of computing services, tools and applications such as servers, storage, databases, networking, software, apps, among others.',
            title2: 'Our Services',
            title3: 'Healthcare Segments',
            title4: 'Our Skills',
            img1: 'assets/img/projects/img2.jpg',
            img2: 'assets/img/services/charts.jpg'
          },
          {
            subTitle: 'UX/UI Modernization',
            title1: 'Human-centered design for a digital-first world',
            paragraphText1: 'With user expectations at an all-time high, your company needs a digital experience that is intuitive, beautiful, and optimized across web, tablet and mobile devices. Through a human-centric design methodology, our designers deliver creative solutions that will resonate with your target audience while advancing your business objectives.Our team focuses on your user and their journey throughout the experience in order to challenge and solve for business objectives, technical limitations and accomplish the user\'s goals.',
            paragraphText2: 'Our process focuses on creating compelling experiences that are grounded in user data and business goals while carefully designing to deliver on the user’s needs. This collaborative process brings together business and technology vision holders to ensure our designs meet your business objectives, are scalable for future growth, and are feasible from a technology perspective. We apply a structured approach to identifying and developing relevant personas. We establish an accurate and succinct representation of actual users of the solution. This phase enables us to obtain human-centric insights on customer desires and challenges.',
            title2: 'Our Capabilities',
            title3: 'Healthcare Segments',
            title4: 'Technologies That We Use',
            img1: 'assets/img/projects/img2.jpg',
            img2: 'assets/img/services/charts.jpg'
          },
          {
            subTitle: 'Insurance',
            title1: 'Insurance IT Experts',
            paragraphText1: 'Insurance industry is changing at “warp speed”, globally! Insurance organizations (carriers, reinsurers, brokers, Third Party Administrators) are focusing on transforming themselves to meet ever changing needs of their customers by leveraging and investing in new age technologies & solutions. Disruption in the form of InsurTech and Digital Technologies is leading to newer methods of customer & partner engagement. Artificial intelligence, machine learning, intelligent automation, blockchain and digitization are driving transformation initiatives across the industry.',
            paragraphText2: 'We provide a wide range of IT services, consulting services to the global Property & Casualty industry for more than 2 decades. Our services span across the insurance value chain in personal lines, commercial lines, specialty lines & reinsurance. We believe that the key drivers of change in the P&C insurance industry are Digitization, Core Insurance Transformation, Intelligent Automation, Big Data, Artificial Intelligence, IoT (Internet of Things) and Cloud Capabilities.',
            title2: 'Solution Offerings', title3: 'Healthcare Segments',
            title4: 'Technologies areas we serve',
            img1: 'assets/img/projects/img2.jpg',
            img2: 'assets/img/services/charts.jpg'
          },
          {
            subTitle: 'Manufacturing',
            title1: 'Enabling Operations to run smoothly',
            paragraphText1: 'The COVID-19 pandemic has disrupted industries across the globe. Without any notice, businesses are thrown in deep waters due to the unforeseen changes that are currently sweeping our lives. At Venturesoft, we create simple, smart, and scalable next-generation supply chain solutions. Many leading manufacturing and hi-tech companies trust us for reshaping their strategies and converting possibilities to reality',
            paragraphText2: 'Our Enterprise 360 solutions suite enables manufacturing companies to embrace the digital world seamlessly while preserving years of investment in traditional operational systems. Our Enterprise 360 strategy brings advanced capabilities needed to support new business models, insight-driven customer engagement, and co-creation through partner ecosystems by leveraging emerging technologies such as IoT, AI/ML, NLP, cognitive computing, AR, and VR.',
            title2: 'Services', title3: 'Healthcare Segments',
            title4: 'Industries We serve',
            img1: 'assets/img/projects/img2.jpg',
            img2: 'assets/img/services/charts.jpg'
          },
          {
            subTitle: 'Telecom',
            title1: 'Being Resilient. Foster Innovation',
            paragraphText1: 'Accelerated technology development is leading us towards an AI-driven connected world. Characterized by ubiquitous internet access, self-learning robots, and truly intuitive human-machine interaction, this new world is creating advanced technology capabilities in consumer devices, networks, and applications. Network infrastructure is changing to adapt to 5G, SDN, NFV, cloud native network functions, disaggregation, open source software, AI, machine learning, and distributed computing. These technological advances have changed end-user consumption patterns, and the emergence of a new customer base beyond traditional telecom service providers is creating a new reality for Network Edge Providers (NEPs).',
            paragraphText2: '', 
            title3: 'Healthcare Segments',
            title2: 'Areas of Specializations',
            title4: 'Solutions',
            img1: 'assets/img/projects/img2.jpg',
            img2: 'assets/img/services/charts.jpg'
          },
          {
            subTitle: 'Education',
            title1: 'Navigating your vision during and after COVID',
            paragraphText1: 'In early 2020, the coronavirus (COVID-19) plunged the world into an unprecedented education crisis that has affected over 1.2 billion learners. The pandemic has driven several countries to explore new models of delivering education that could augment the physical classroom.',
            paragraphText2: 'Venturesoft partners with schools and universities to develop a digital ecosystem that senses & responds to the needs of all stakeholders. Students benefit from personalized learning, teachers create customized content, complete online assessments & parents follow performance of their children. Administrators leverage digital tools to streamline operations - from student enrollment to campus recruitment.',
            title2: 'Expertise Areas',
            title4: 'Segments',
            title3: 'Healthcare Segments',
            img1: 'assets/img/projects/img2.jpg',
            img2: 'assets/img/services/charts.jpg'
          },
          {
            subTitle: 'Digital Startups',
            title1: 'Need a partner for Flexible development and growth?',
            paragraphText1: 'Building a functional product isn’t enough to be successful in todays highly competitive landscape. Success today means delivering a product that stands apart from competition, marketing that product in an ROI positive manner, and – if going the route of VC funding – hitting important growth milestones along the way. Coming from the world of startups ourselves, we know what it takes to accomplish these daunting tasks. Our unique backgrounds and tight coupling of development and advertising (dev sits a desk away from advertising, not a floor away) result in an ability to launch and scale seamlessly, quick product iterations, and a short feedback loop from ad performance to conversion rate optimizations and back again.',
            paragraphText2: 'We are unique in that we are a tight-knit agency with startup roots. This allows us to provide personalized attention to our clients, implement solutions with next-generation technology/tools, and align incentive structure with our startup clientele. This means highly functional products with a clear path to profitability. Even if you’re not sure going the agency route is right for your brand, we’re happy to provide a free strategy consultation to go over your technical architecture, growth strategy, sales processes and market feasibility.',
            title2: 'Where can we help?',
            title3: 'Healthcare Segments',
            title4: 'Who we help?',
            img1: 'assets/img/projects/img2.jpg',
            img2: 'assets/img/services/charts.jpg'
          },
          {
            subTitle: 'Robotic Process Automation',
            title1: 'Accelerate to an Intelligent Enterprise',
            paragraphText1: 'RPA is becoming a major enterprise enabler in improving the efficiency and effectiveness of an organization’s operations and service delivery and as a foundation for digital transformation. Where the application of RPA becomes interesting is when it is combined with more advanced, cognitive artificial intelligence tools to deliver intelligent process automation.',
            paragraphText2: '',
            title2: 'Focus Areas',
            title3: 'Healthcare Segments',
            title4: 'Our Automation Partners',
            img1: 'assets/img/projects/img2.jpg',
            img2: 'assets/img/services/charts.jpg'
          },
          {
            subTitle: 'SAP Practice',
            title1: 'Accelerate your intelligent enterprise',
            paragraphText1: 'Ventureosft\'s SAP practice provides enterprises with end-to-end consulting, implementation and support services. Our deep industry domain experience backed by our proprietary methodologies, frameworks and SAP certified tools and accelerators help enterprises accelerate their digital transformation journey. Our SAP portfolio offers extensive expertises on implementation and application management for building an Intelligent Enterprise with SAP S/4HANA and Intelligent Technologies. It also includes accelerating Cloud adoption with Cloud applications and platform migration, modernizing applications with process consulting and automation',
            paragraphText2: 'We employ the best and brightest in the SAP field and continuously invest in transformation methods, project accelerators, industry apps and innovative delivery models to ensure we deliver award-winning services to our customers. Wherever your business wants to go with SAP, VSG can support your journey.',
            title2: 'Our Services',
            title3: 'Segments',
            title4: 'Industries we serve',
            img1: 'assets/img/projects/img2.jpg',
            img2: 'assets/img/services/charts.jpg'
        },
    ]
    servicesDetailsDescImportantFacts: ImportantFacts[] = [
        {
            title: ['Cloud Security','Application Security','Data Loss Prevention','Distributed Denial of Service','Firewalls','Email Security']
        },
        {
            title: ['Health & Human Services','Health Plan','Healthcare Distribution','Care Delivery','Healthcare IT support','Data Security & Diasaster Recovery']
        },
        {
            title: ['Retail Banking', 'Corporate Banking','Investment Banking','Cards & Payments','Wealth Management','Anti-Money Laundering']
        },
        {
            title: ['Data Ingestion','Data Preparation','Algorithm Identification','Model Executions','Ranking and Scoring Data','Training/Retraining Models']
        },
        {
            title: ['Actionable insights','Market-leading accuracy','Unrivalled speed','Total transparency','Compliance','Cost-effectiveness']
         },
        {
            title: ['Cloud Strategy Services','Application Development Services','Maintenance and Security Services','Cloud Migration Services','Monitoring Services','Cloud Infrastructure Management']
        },
        {
            title: ['User Experience (UX) Design','Rapid Prototyping','Motion & Interaction Design','User Interface (UI) Design','User Testing and Accessibility','Content Production','Conversion Rate Optimization']
        },
        {
            title: ['AI Based Claims Services','Data Risk Aggregation','Data Analytics','Lead Generation','Customer Support','Underwriting','Robotic Processes']
        },
        {
            title: ['Cloud Strategy Services','Application Development Services','Maintenance and Security Services','Cloud Migration Services','Monitoring Services','Cloud Infrastructure Management']
        },
        {
            title: ['Process Improvements', 'IT Ops', 'Business Intelligence', 'Cloud Support']
        },
        {
            title: ['Smart Campus Solutions','Offline Digital Learning','Student Information System','Technology Services','Online Assessment Systems','Admission Services','Colloboration Tools']
        },
        {
            title: ['IT Development', 'Cloud Startegy', 'Database managemnt', 'Identity Management', 'Digital strategy', 'Web and mobile development','UX Design']
        },
        {
            title: ['Supply Chain', 'ITSM', 'Customer Service', 'Finance & Accounting', 'IT Compliance & Reporting', 'Data Migration', 'Master Data Management']
        },
         {
             title: ['Business Transformation', 'Customer Experience', 'Digital Supply Chain', 'Human Experience', 'Intelligent Systems', 'Cloud Adoption']
         }
        
    ]
    servicesDetailsDescApplication: Application[] = [
        {
            title: 'Manufacturing',
            icon: 'flaticon-factory'
        },
        {
            title: 'Healthcare',
            icon: 'flaticon-hospital'
        },
        {
            title: 'Automobile',
            icon: 'flaticon-tracking'
        },
        {
            title: 'Banking',
            icon: 'flaticon-investment'
        },
        {
            title: 'Real Estate',
            icon: 'flaticon-house'
        },
        {
            title: 'Logistics',
            icon: 'flaticon-order'
        }
    ]
    servicesDetailsDescTechnologiesFeatures: TechnologiesFeatures[] = [
        {
            title: ['Security Solutions','Phishing Trainig & Testing','Managed Security Operations','Managed Network Security','IT Policy Mamagement','Security Definitions']
        },
        {
            title: ['HIPPA Compliance','Telemedicine','Hospitals & Health Systems','Data Governance','Regulatory Compliances']
        },
        {
            title: ['Actimize NICE','BluePrism','DynaTrce','SauceLabs','React Native','Block Chain']
        },
        {
            title: ['Spark ML','Tensor Flow','Amazon ML','Amazon Lex','Azure EMotion API']
        },
        {
            title: ['Spark ML','Tensor Flow','Amazon ML','Amazon Lex','Azure EMotion API', 'Azure Cognitive Services']
        },
        {
            title: ['Amazon Web Services','Google Cloud Platform','Microsoft Azure','IBM Cloud','Oracle Cloud']
        },
        {
            title: ['Sketch','Adobe XD','Figma','Invision Studio','Axure','Marvel','Zeplin','Javascript']
        },
        {
            title: ['Robotic Process Automation', 'Machine Learning', 'Claims Management', 'Insurance Pricing', 'Telematics Insurance', 'Chatbots',]
        },
        {
            title: ['Medical Equipments','Industrial','Hi-Tech','Chemical Process','Aerospace','Automotive']
        },
        {
            title: ['Oracle Suites', 'AmDocs Billing System', 'Oracle BRM', 'Cloud Support', 'Digital Transformation']
        },
        {
            title: ['IT Infrastructure', 'Cloud Support', 'Marketing Campaigns','Security', 'Process Management', 'Research Managment']
        },
        {
            title: ['Small Businesses', 'Finacial Institutions', 'Law Firms', 'Silicon Valley Startups', 'Healthcase Providers', 'Educatioal Institutions', 'Restaurants']
        },
        {
            title: ['Automation Anywhere', 'Blue Prism', 'Pega','UI Path']
        },
        {
            title: ['Manufacturing & Auto', 'Energy & Utilities', 'Health Care', 'Hi-Tech', 'Telecom']
        }
        
    ]

    sidebarServicesList: ServicesList[] = [
        {
            title: 'Data Science',
            link: 'services-details',
            item: 3
        },
        {
            title: 'Cloud Solutions',
            link: 'services-details',
            item: 5
        },
        {
            title: 'Robotic Process Automation',
            link: 'services-details',
            item: 12
        },
        {
            title: 'UX/UI Modernization',
            link: 'services-details',
            item: 6
        },
        {
            title: 'Machine Learning',
            link: 'services-details',
            item: 4
        }
    ]
    sidebarDownloadFile: DownloadFile[] = [
        {
            title: 'PDF Download',
            icon: 'bx bxs-file-pdf',
            link: 'services-details'
        },
        {
            title: 'Services Details.txt',
            icon: 'bx bxs-file-txt',
            link: 'services-details'
        }
    ]
    sidebarContactInfo: ContactInfo[] = [
        {
            icon: 'bx bx-user-pin',
            title: 'Phone',
            subTitle: '+1 925-353-5023'
        },
        {
            icon: 'bx bx-map',
            title: 'Location',
            subTitle: 'Pleasanton, CA'
        },
        {
            icon: 'bx bx-envelope',
            title: 'Email',
            subTitle: 'info@vstglobal.com'
        }
    ]

    changeItem(i) {
        this.currentStep = i;
    }
    
}
class pageTitle {
    title : string;
    subTitle : string;
}
class DetailsImage {
    img : string;
}
class DetailsDesc {
    subTitle : string;
    title1 : string;
    title2 : string;
    title3 : string;
    title4 : string;
    paragraphText1 : string;
    paragraphText2 : string;
    img1 : string;
    img2 : string;
}
class ImportantFacts {
    title : string[];
}
class Application {
    title : string;
    icon: string;
}
class TechnologiesFeatures {
    title : string[];
}

class ServicesList {
    title : string;
    link : string;
    item: number
}
class DownloadFile {
    title : string;
    icon : string;
    link : string;
}
class ContactInfo {
    icon : string;
    title : string;
    subTitle : string;
}