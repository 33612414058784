import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    constructor(private route: Router) { }

    ngOnInit(): void {
    }

    goToPage(p) {
        this.route.navigate(['services-details'], { queryParams: { type: p } })  
    }

}